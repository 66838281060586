<template>
<div>
    <v-card flat  :color="TemaDark?'background2':'primary'" height="50" class="card-bk rounded-0" width="100%" v-if="!skeleton"/>
    <v-row>
        <v-col cols="12">
            <v-card flat class="rounded-lg d-flex flex-column">
                <!-- Overlay -->
                <v-overlay :opacity=".6" :color="TemaDark?'grey darken-4':'white'" :value="overlay" z-index="2" class="rounded-lg" absolute />
                <v-card v-if="overlay" class="loader-overlay d-flex justify-center align-center rounded-lg" flat height="0px" width="22vh">
                    <v-row class="fill-height" align-content="center" justify="center">
                        <v-col class="subtitle-1 text-center pa-0" cols="12">
                            Cargando...
                        </v-col>
                        <v-col cols="6 pt-0">
                            <v-progress-linear color="primary" indeterminate rounded height="6"></v-progress-linear>
                        </v-col>
                    </v-row>
                </v-card>
                <!-- Toolbar -->
                <v-card flat class="rounded-lg py-0 px-2 planilla-title">
                    <v-toolbar flat v-if="!skeleton" class="rounded-lg ">
                        <v-menu ref="menu" v-model="menu_mes" :close-on-content-click="false" right origin="top left" transition="scroll-x-transition" rounded="lg">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon class="mr-2 " :class="TemaDark?'primary':'secondary'" v-bind="attrs" v-on="on" style="margin-left: -8px;height: 42px;width: 42px;">
                                    <v-icon :color="TemaDark?'white':'primary'" class="icon_btn_picker" size="20">mdi-calendar</v-icon>
                                </v-btn>
                            </template>
                            <v-card flat class="rounded-lg">
                                <v-date-picker v-model="fecha" type="date" scrollable flat :show-current="true" no-title @change="$refs.menu.save(GetFecha(fecha, 0))" width="280" first-day-of-week="1" :min="rango.inicio" :max="rango.termino" show-adjacent-months>
                                    <v-spacer></v-spacer>
                                    <v-btn text color="primary" @click="GetFecha(FechaActual.calendario, 0);menu_mes = false">Hoy</v-btn>
                                    <v-btn text color="primary" @click="menu_mes = false">Cerrar</v-btn>
                                </v-date-picker>
                            </v-card>
                        </v-menu>
                        <v-toolbar-title class="pa-0">
                            <v-list-item class="px-0">
                                <v-list-item-content>
                                    <v-list-item-title class="headline mb-0">
                                        <v-toolbar-title class="pa-0 trenos-title-toolbar" :class="TemaDark?'grey--text text--lighten-3':'grey--text text--darken-1'" style="line-height: 1.1;">
                                            {{FechaNumero}} de 
                                            <span class="text-capitalize">{{FechaMes}}</span>
                                        </v-toolbar-title>
                                    </v-list-item-title>
                                    <v-list-item-subtitle class="font-weight-medium mb-0">
                                        <span class="text-capitalize">{{Nombredia}}</span>
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-menu v-model="edit_menu" :close-on-content-click="true" :nudge-width="200" origin="right top" rounded="lg"  left transition="scale-transition" max-width="200" v-if="edit">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" v-on="on">
                                    <v-icon color="primary" size="20">mdi-cog-outline</v-icon>
                                </v-btn>
                            </template>
                            <v-list dense>
                                <v-list-item @click="OpenDialog('tabla_asignaciones')">
                                    <v-list-item-icon class="mr-3">
                                        <v-icon size="20">mdi-upload</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-title>Asignacion mensual</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                        <v-btn icon @click="GetFecha(fecha,-1)">
                            <v-icon color="primary" size="20">mdi-chevron-left</v-icon>
                        </v-btn>
                        <v-btn icon @click="GetFecha(fecha,1)">
                            <v-icon color="primary" size="20">mdi-chevron-right</v-icon>
                        </v-btn>
                    </v-toolbar>
                </v-card> 
                <v-card class="pa-0 card-vista rounded-lg" flat min-height="50vh">
                    <v-container>
                        <div v-if="skeleton" class="rounded-lg">
                            <v-skeleton-loader type="list-item-avatar-two-line" />
                        </div>
                        <v-row v-else>
                            <v-col cols="6" sm="6" class="py-1 pr-1">
                                <v-card outlined class="hidden-md-and-up text-center rounded-lg ml-1" @click="OpenDialog('planilla_il')">
                                    <v-avatar color="secondary" size="40" class="mt-2" rounded="xl">
                                        <v-icon size="22" color="primary">
                                            mdi-account-group-outline
                                        </v-icon>
                                    </v-avatar>
                                    <v-toolbar-title class="text-subtitle-2 my-2" :class="TemaDark?'grey--text text--lighten-3':'grey--text text--darken-1'" style="line-height: 1;">Insp. de Linea</v-toolbar-title>
                                </v-card>
                                <v-card flat class="rounded-lg mx-auto hidden-sm-and-down" max-width="400">
                                    <v-toolbar flat height="50" class="rounded-lg">
                                        <v-list-item class="px-0" style="min-height:40px">
                                            <v-list-item-content class="py-0">
                                                <v-list-item-title class="headline mb-0">
                                                    <v-toolbar-title class="pa-0" :class="TemaDark?'grey--text text--lighten-3':'grey--text text--darken-2'" style="line-height: 1;">Insp. de Linea</v-toolbar-title>
                                                </v-list-item-title>
                                                <v-list-item-subtitle class="font-weight-medium mb-0">
                                                    <span class="text-capitalize">Asignaciones</span>
                                                </v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-spacer />
                                        <v-btn text color="primary" depressed @click="OpenDialog('planilla_il')">
                                            Ver
                                        </v-btn>
                                    </v-toolbar>
                                </v-card>
                            </v-col>
                            <v-col cols="6" sm="6" class="py-1 pl-1">
                                <v-card outlined class="hidden-md-and-up text-center rounded-lg mr-1" @click="OpenDialog('planilla_obs')">
                                    <v-avatar color="secondary" size="40" class="mt-2" rounded="xl">
                                        <v-icon size="22" color="primary">
                                            mdi-account-group-outline
                                        </v-icon>
                                    </v-avatar>
                                    <v-toolbar-title class="text-subtitle-2 my-2" :class="TemaDark?'grey--text text--lighten-3':'grey--text text--darken-1'" style="line-height: 1;">Personal Maniobras</v-toolbar-title>
                                </v-card>
                                <v-card flat class="rounded-lg mx-auto hidden-sm-and-down" max-width="400">
                                    <v-toolbar flat height="50" class="rounded-lg">
                                        <v-list-item class="px-0" style="min-height:40px">
                                            <v-list-item-content class="py-0">
                                                <v-list-item-title class="headline mb-0">
                                                    <v-toolbar-title class="pa-0" :class="TemaDark?'grey--text text--lighten-3':'grey--text text--darken-2'" style="line-height: 1;">Personal Disponible</v-toolbar-title>
                                                </v-list-item-title>
                                                <v-list-item-subtitle class="font-weight-medium mb-0">
                                                    <span class="text-capitalize">Apoyo Maniobras</span>
                                                </v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-spacer />
                                        <v-btn text color="primary" depressed @click="OpenDialog('planilla_obs')">
                                            Ver
                                        </v-btn>
                                    </v-toolbar>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-container>
                    <v-container>
                        <div v-if="skeleton" class="rounded-t-lg">
                            <v-skeleton-loader type="list-item-avatar-two-line" />
                        </div>
                        <v-row v-if="!skeleton" class="pb-2">
                            <v-col cols="12" sm="6" class="py-0">
                                <v-card v-for="(entrada) in entradas" :key="entrada.nombre" flat>
                                    <PlanillaLista :lista="entrada" :col="0" :fds="CheckFDS" :fecha="fecha" :edit="edit" @click_turno="GetTurno($event)"/>
                                </v-card>
                            </v-col>
                            <v-col cols="12" sm="6" class="py-0">
                                <v-card v-for="(entrada) in entradas" :key="entrada.nombre" flat>
                                    <PlanillaLista :lista="entrada" :col="1" :fds="CheckFDS" :fecha="fecha" :edit="edit" @click_turno="GetTurno($event)"/>
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-row v-else>
                            <v-col cols="12" sm="6">
                                <v-skeleton-loader type="list-item-avatar" v-for="(item, index) in 10" :key="index" />
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-skeleton-loader type="list-item-avatar" v-for="(item, index) in 10" :key="index" />
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-card>
        </v-col>
    </v-row>
    <!-- Dialog Turno-->
    <v-dialog v-model="dialog.turno" persistent max-width="450" :transition="DialogAnimation" :overlay-opacity="TemaDark?'.5':'.25'" overlay-color="black">
        <v-fade-transition>
            <v-card flat class="rounded-lg" v-show="!DialogAsignar && !DialogEditar">
                <Turno :fecha="fecha" :id="turno_id" :in_dialog="true" :only_read="true" :dialog_btn="true" :vista="'planilla'"/>
            </v-card>
        </v-fade-transition>
    </v-dialog>
    <!-- Dialog Observaciones-->
    <v-dialog v-model="dialog.planilla_obs" persistent max-width="450" :transition="DialogAnimation" class="rounded-lg" :overlay-opacity="TemaDark?'.5':'.25'" overlay-color="black">
        <v-card class="rounded-lg">
            <v-card-title class="pa-0 modal-title">
                <v-toolbar flat class="rounded-t-lg" height="56">
                    <v-toolbar-title class="pa-0 text-capitalize" style="line-height: 1.1;">
                        <v-list-item class="pl-0 rounded-t-lg">
                            <v-list-item-avatar class="mr-2 rounded-lg" :color="TemaDark?'primary':'secondary'" tile width="8" height="30" min-width="8"/>
                            <v-list-item-content class="py-0">
                                <v-list-item-title class="headline pb-0 mb-0">
                                    <v-toolbar-title class="pa-0 trenos-title-toolbar text-capitalize" :class="TemaDark?'grey--text text--lighten-3':'grey--text text--darken-1'" style="line-height: 1.1;">Personal Disponible</v-toolbar-title>
                                </v-list-item-title>
                                <v-list-item-subtitle class="font-weight-medium">
                                    <span class="text-capitalize">{{FechaActual.nombre}}</span>
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-toolbar-title>
                </v-toolbar>
            </v-card-title>
            <v-card flat max-height="70vh" style="overflow: auto;">
                <div v-if="entradas.length && entradas.some(entrada => entrada.nombre === 'Observaciones')" class="pb-2 px-3">
                    <v-list v-for="(lugar, index) in entradas[entradas.findIndex(entrada => entrada.nombre === 'Observaciones')].turnos" :key="index" class="rounded-lg list-planilla px-1 py-0">
                        <v-list-item class="pl-2">
                            <v-list-item-content class="py-0">
                                <v-list-item class="pa-0 list-planilla">
                                    <v-list-item-content class="py-0">
                                        <v-list-item-title class="text-subtitle-2 text-capitalize" :class="TemaDark?'grey--text text--lighten-3':'grey--text text--darken-1'">
                                            <v-icon size="15" class="mr-2 mb-1">mdi-map-marker-circle</v-icon>{{lugar.nombre}}
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-for="(turno, index) in lugar.lista" :key="index" class="rounded-lg list-planilla px-1">
                            <v-list-item-avatar class="my-0 mr-1 avatar-otros" height="48">
                                <v-avatar :color="TemaDark?'grey darken-3':'grey lighten-4'" size="35">
                                    <span class="text-body-2 font-weight-medium turno-title text-uppercase" :class="TemaDark?'grey--text text--lighten-1':'grey--text text--darken-1'">
                                        {{turno.nombre}}
                                    </span>
                                </v-avatar>
                            </v-list-item-avatar>
                            <v-list-item-content class="py-0">
                                <v-list-item class="pa-0 list-planilla">
                                    <v-list-item-content class="py-0">
                                        <v-list-item-title class="text-subtitle-2 text-capitalize" :class="TemaDark?'grey--text text--lighten-3':'grey--text text--darken-1'">{{turno.maquinista[0]}}</v-list-item-title>
                                        <v-list-item-subtitle class="text-capitalize">{{turno.detalle}}</v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-action class="ma-0">
                                        <span class="text-subtitle-2 grey--text">{{turno.inicio}} - {{turno.termino}}</span>
                                    </v-list-item-action>
                                </v-list-item>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </div>
                <v-list v-else>
                     <v-row justify="center" align-content="center" class="text-center ma-0">
                        <v-col cols="12">
                            <v-card flat height="120" class="justify-center align-center d-flex flex-column">
                                <v-icon size="50" color="grey lighten-1">
                                    mdi-alert-circle-outline
                                </v-icon>
                                <span class="trenos-title-toolbar grey--text text--lighten-1">Sin informacion</span>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-list>
            </v-card>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="CloseDialog('planilla_obs')">
                    Cerrar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <!-- Dialog Lista Ils-->
    <v-dialog v-model="dialog.planilla_il" persistent max-width="450" :transition="DialogAnimation" class="rounded-lg" :overlay-opacity="TemaDark?'.5':'.25'" overlay-color="black">
        <v-card flat>
            <Ils :fecha="fecha" vista="planilla"/>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="CloseDialog('planilla_il')">
                    Cerrar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <TablaAsignaciones />
</div>
</template>

<script>
import { mapState, mapMutations} from 'vuex'
import dayjs from "dayjs"
export default {
    components: {
        Ils: () => import('@/components/Ils'),
        PlanillaLista: () => import('@/components/PlanillaLista'),
        TablaAsignaciones: () => import('@/components/TablaAsignaciones'),
        Turno: () => import('@/components/Turno')
    },
    name: 'Planilla',
    data() {
        return {
            fecha: dayjs().format('YYYY-MM-DD'),
            menu_mes: false,
            overlay: false,
            skeleton: true,
            entradas: [],
            turno_id: null,
            dialog_obs: false,
            planilla: true,
            edit: 0,
            rango: {},
            dialog_il: false,
            dialog_turno: false,
            edit_menu: false
        }
    },
    computed: {
        ...mapState(['usuario', 'dialog', 'update','sistema']),
        FechaNumero() {
            return dayjs(this.fecha).format('DD')
        },
        FechaMes(){
            return dayjs(this.fecha).format('MMMM')
        },
        CheckFDS() {
            let fds = true
            for (let index = 0; index < this.entradas.length; index++) {
                if (this.entradas[index].nombre.includes('Part-Time')) fds = false
            }
            return fds
        },
        UpdateComponente() {
            return this.update.planilla
        },
        Nombredia() {
            return dayjs(this.fecha).format('dddd')
        },
        FechaActual(){
            let fecha_actual = {
                calendario : dayjs().format('YYYY-MM-DD'),
                nombre: dayjs(this.fecha).format('dddd DD')+ ' de '+ dayjs(this.fecha).format('MMMM')
            }
            return fecha_actual
        },
        TemaDark(){
            let dark = false
            if(this.sistema.tema == 'dark' || (this.sistema.tema == 'system' && window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches)) dark = true
            return dark
        },
        DialogAnimation(){
            let animation = 'fade-transition'
            if(!this.$vuetify.breakpoint.mdAndUp) animation = 'dialog-bottom-transition'
            return animation
        },
        DialogAsignar(){
            let hide_dialog = false
            if(this.dialog.asignar) hide_dialog = true
            return hide_dialog
        },
        DialogEditar(){
            let hide_dialog = false
            if(this.dialog.editar) hide_dialog = true
            return hide_dialog
        },
    },
    methods: {
        ...mapMutations(['ErrorRequest', 'OpenDialog', 'CloseDialog']),
        async GetData(fecha) {
            this.overlay = true
            await this.axios.get(`/planilla/${fecha}`, {
                headers: {
                    token: this.usuario.token
                }
            }).then(respuesta => {
                if (respuesta.data.mensaje) {
                    this.ErrorRequest(respuesta.data.mensaje)
                } else {
                    this.overlay = false
                    this.skeleton = false
                    this.entradas = respuesta.data.entradas
                    this.edit = respuesta.data.edit
                    this.rango = respuesta.data.rango
                }
            }).catch(err => {
                this.overlay = false
                this.ErrorRequest(err)
            })
        },
        GetFecha(fecha, operacion) {
            switch (operacion) {
                case 0: //cambiar mes
                    this.GetData(dayjs(fecha).format('YYYYMMDD'), this.usuario_id)
                    this.fecha = fecha
                    break;
                case 1: //dia siguiente
                    this.GetData(dayjs(fecha).add(1, 'day').format('YYYYMMDD'), this.usuario_id)
                    this.fecha = dayjs(fecha).add(1, 'day').format('YYYY-MM-DD')
                    break;
                case -1: //dia anterior
                    this.GetData(dayjs(fecha).subtract(1, 'day').format('YYYYMMDD'), this.usuario_id)
                    this.fecha = dayjs(fecha).subtract(1, 'day').format('YYYY-MM-DD')
                    break;
            }
        },
        GetTurno(turno_id) {
            this.turno_id = turno_id
            //this.dialog_turno = true
            this.OpenDialog('turno')
        },
    },
    mounted() {
        window.scrollTo(0, 0)
        this.GetData(dayjs(this.fecha).format('YYYYMMDD'))
    },
    watch: {
        UpdateComponente: function (val) {
            if (val) {
                this.GetData(this.fecha)
                this.update.planilla = false
            }
        }
    }
}
</script>

<style scoped>
.loader-overlay {
    background-color: transparent;
    position: sticky;
    top: 400px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    z-index: 2;
}

@media only screen and (max-width: 960px) {
    .planilla-title {
        position: sticky;
        top: calc(64px + env(safe-area-inset-top));
        background-color: white;
        z-index: 2;
    }
}

@media only screen and (min-width: 961px) {
    .planilla-title {
        position: sticky;
        top: calc(64px + env(safe-area-inset-top));
        background-color: white;
        z-index: 2;
    }
}

.turno-title {
    line-height: .1rem !important;
}

.avatar-otros {
    align-self: start !important;
}

.list-planilla {
    min-height: 32px !important;
    max-width: 100%;
}

.v-list-item--link:before {
    border-radius: 8px !important;
}

.icon-servicio {
    vertical-align: text-top !important;
}

.card-vista{
    display: flex;
    flex-direction: column;
    scroll-behavior: smooth;
}

.card-bk{
    position: fixed;
    top: 64px;
    z-index: 2;
}

.v-menu__content >>> .theme--dark.v-list{
    background-color: #424242 !important;
}
</style>